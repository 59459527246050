import styled from 'styled-components';

import Container from '@components/layout/common/container';
import Wrap from '@components/layout/common/wrap';
import { breakpoint } from '@constants/theme';

export const Style = {
  Wrap: styled(Wrap)``,
  Container: styled(Container)``,
  ShopBanner: styled.img.attrs((props) => ({
    src: props.src,
  }))`
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 200px;
    box-sizing: border-box;
    display: block;
    animation: loading 2s ease-in-out infinite;

    @keyframes loading {
      0% {
        background: rgb(243 243 243 / 0%);
      }
      50% {
        background: rgb(243 243 243 / 80%);
      }

      100% {
        background: rgb(243 243 243 / 0%);
      }
    }
  `,
  Products: styled.div`
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin: 0.5rem -0.25rem 2rem;
    font-style: normal;

    .product-card {
      padding: 0.25rem 0.75rem;
      margin-bottom: 2rem;
      flex: 0 0 25%;
      max-width: 25%;
      box-sizing: border-box;
      min-width: 180px;

      .product-img {
        width: 100%;
        height: 23vw;
        min-width: 180px;
        min-height: 180px;
        max-width: 235px;
        max-height: 235px;
        border-radius: 15px;
        object-fit: cover;
        object-position: center;
        margin: auto;
        display: block;
      }

      .product-header {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #959595;
        max-width: 235px;
        margin: 0.5rem auto;
      }

      .product-body {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        width: 100%;
        max-width: 235px;
        margin: auto;

        div:first-child {
          max-height: 2.2rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .product-price {
          font-weight: 700;
        }
      }
    }

    @media (max-width: ${breakpoint.md}px) {
      margin-left: 0px;
      margin-right: 0px;

      .product-card {
        flex: 0 0 50%;
        max-width: 50%;
        min-width: 150px;

        .product-img {
          min-width: 120px;
          min-height: 120px;
          max-width: 130px;
          max-height: 130px;
          height: 40vw;
        }
      }
    }

    .add-cart-btn {
      width: 100%;
      border: 1px solid orange;
      font-family: 'Inter';
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #f39800;
      text-align: center;
      box-sizing: border-box;
      padding: 0.75rem;
      margin-top: 1rem;
      border-radius: 10px;

      &:active {
        background: rgba(0, 0, 0, 0.1);
      }

      @media (max-width: ${breakpoint.md}px) {
        font-size: 14px;
        padding: 0.5rem;
        margin-top: 0.75rem;
      }
    }
  `,
  Card: styled.div.attrs((props: { md: number; sm: number }) => props)`
    box-sizing: border-box;

    @media (min-width: ${breakpoint.md + 1}px) {
      ${(props) => (props.md ? `flex: 0 0 ${(100 * props.md) / 12}%;` : '')}
      ${(props) => (props.md ? `max-width:  ${(100 * props.md) / 12}%;` : '')}
      ${(props) => (props.md ? 'padding-left:  10px;' : '')}
      ${(props) => (props.md ? 'padding-right:  10px;' : '')}
      ${(props) => (props.md ? 'margin-top:  20px;' : '')}
    }

    @media (max-width: ${breakpoint.md}px) {
      ${(props) => (props.sm ? `flex: 0 0 ${(100 * props.sm) / 12}%;` : 'flex: 0 0 100%;')}
      ${(props) => (props.sm ? `max-width:  ${(100 * props.sm) / 12}%;` : 'max-width: 100%;')}
      ${(props) => (props.sm ? 'margin-top:  20px;' : '')}
    }

    .card {
      ${(props) => (props.md ? 'height:  100%;' : '')}
      background: #FDF6E8;
      border-radius: 15px;
      padding: 2rem 4rem;
      box-sizing: border-box;
      margin-bottom: 1rem;
      font-style: normal;

      & > .card-header {
        font-family: 'Inter';
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        align-items: center;

        & > .card-header-icon {
          object-fit: cover;
          object-position: center;
          width: 38px;
          height: 38px;
          vertical-align: middle;
          margin-bottom: 5px;
        }

        & > p {
          display: flex;
          align-items: center;
        }

        & .card-date {
          vertical-align: baseline;
          display: inline-block;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #959595;
          margin-right: 20px;
        }
      }

      & > .card-body {
        margin-top: 1rem;

        .card-description {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
        }

        .card-link {
          display: block;
          text-decoration-line: underline;
          color: #f39800;
          margin-top: 1.25rem;
        }

        .card-table {
          width: 100%;
          margin-bottom: 1rem;

          tr {
            th {
              background: #f39800;
              padding: 5px;
              border: 1px solid #fdf6e8;
              color: #ffffff;
              text-align: center;
              font-size: 14px;
            }

            th:first-child {
              background: var(--color-bg-gray);
              border: 1px solid #fdf6e8;
              color: #0f0f0f;
            }

            td {
              background: white;
              padding: 5px;
              border: 1px solid #fdf6e8;
              text-align: center;
              color: #0f0f0f;
              font-size: 14px;
              text-align: center;
            }

            td:first-child {
              background: var(--color-bg-gray);
              border: 1px solid #fdf6e8;
              color: #0f0f0f;
            }
          }
        }
      }

      @media (max-width: ${breakpoint.md}px) {
        padding: 1rem 0.75rem;

        & .card-header {
          font-size: 16px;

          > .card-header-icon {
            width: 32px;
            height: 32px;
          }
        }

        & .card-body {
          .card-description {
            font-size: 12px;
          }
        }
      }
    }
  `,
};
