import { navigate } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { FC, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setEGiftCard } from '../../../redux/slices/egiftCardSlice';

import * as img from '@assets/image/png';
import Button from '@components/common/Button';
import { Layout } from '@components/layout';
import { Grid } from '@components/layout/grid';
import { Style } from '@features/e-cart/style';

interface Img {
  Template1: 'Template1';
  Template2: 'Template2';
  Template3: 'Template3';
  Template4: 'Template4';
  Template5: 'Template5';
  Template6: 'Template6';
  Template7: 'Template7';
  Template8: 'Template8';
}

const EGiftTemplateSelectPage: FC = () => {
  const eGiftCard = useAppSelector((state) => state.eGiftCardReducer.eGiftCard);
  const dispatch = useAppDispatch();
  const [template, setTemplate] = useState<number>(0);

  useEffect(() => {
    if (eGiftCard) {
      setTemplate(Number(eGiftCard.templateIndex));
    }
  }, [eGiftCard]);

  return (
    <Layout>
      <Style.Wrap>
        <Style.Container>
          <h5 className="text-center my-3 mx-auto" style={{ maxWidth: '300px' }}>
            <FormattedMessage id="e-gift.hint3" />
          </h5>
          <Grid justify="center" md={{ gridTemplateColumns: 'repeat(4, 180px)', gap: '40px' }}>
            {/* TODO temp use it will be adjusted when getting api spec  */}
            {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
              <button
                key={`Template${index}`}
                type="button"
                className={`template${template === index ? ' active' : ''}`}
                onClick={() => {
                  setTemplate(index);
                }}
              >
                <img src={(img as Img)[`Template${index}` as keyof Img]} alt={`Template${index}`} />
              </button>
            ))}
          </Grid>
          <form>
            <div className="form-group">
              <Button
                className="my-3"
                width="250px"
                disabled={!template}
                onClick={() => {
                  dispatch(
                    setEGiftCard({
                      eGiftCard: {
                        templateIndex: Number(template),
                        receiver: eGiftCard?.receiver || '',
                        message: eGiftCard?.message || '',
                        sender: eGiftCard?.sender || '',
                      },
                    })
                  );
                  navigate('/e-gift/template-confirm');
                }}
              >
                <FormattedMessage id="order.next" />
              </Button>
              <Button
                className="my-3 secondary"
                width="180px"
                onClick={() => {
                  navigate('/e-gift/template-message');
                }}
              >
                <FormattedMessage id="back.page" />
              </Button>
            </div>
          </form>
        </Style.Container>
      </Style.Wrap>
    </Layout>
  );
};

export default React.memo(EGiftTemplateSelectPage);
