import styled from 'styled-components';

import { breakpoint } from '@constants/theme';

export default styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 5px;
  box-sizing: border-box;

  @media (max-width: ${breakpoint.md}px) {
    padding-right: 0px;
    padding-left: 0px;
  }
`;
