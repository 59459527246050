import styled from 'styled-components';

import { breakpoint } from '@constants/theme';

export const RowStyle = styled.div`
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
`;

export const ColStyle = styled.div<{
  sm?: number;
  md?: number;
  mdOffset?: number;
  mdOrder?: number;
  offset?: number;
  order?: number;
}>`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 ${(props) => (props.md ? `${String((props.md * 100) / 12)}%` : 'auto')};
  max-width: ${(props) => (props.md ? `${String((props.md * 100) / 12)}%` : 'auto')};
  order: ${(props) => props.mdOrder || 0};
  margin-left: ${(props) => (props.mdOffset ? `${String((props.mdOffset * 100) / 12)}%` : 0)};

  @media (max-width: ${breakpoint.md}px) {
    flex: 0 0 ${(props) => (props.sm ? `${String((props.sm * 100) / 12)}%` : '100%')};
    max-width: ${(props) => (props.sm ? `${String((props.sm * 100) / 12)}%` : '100%')};
    order: ${(props) => props.order || 0};
    margin-left: ${(props) => (props.offset ? `${String((props.offset * 100) / 12)}%` : 0)};
  }
`;
