import styled from 'styled-components';

import { breakpoint } from '@constants/theme';

export type Props = {
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
  gap?: string;
  rowGap?: string;
  columnGap?: string;
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'stretch';
  margin?: string;
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  md?: Omit<Props, 'md'>;
  overflowY?: 'auto';
  maxHeight?: string;
};

export const Grid = styled.div<Props>(({ md, ..._ }) => ({
  display: 'grid',
  gridTemplateColumns: _.gridTemplateColumns,
  gridTemplateRows: _.gridTemplateRows,
  gridGap: _.gap,
  rowGap: _.rowGap,
  maxHeight: _.maxHeight,
  overflowY: _.overflowY,
  columnGap: _.columnGap,
  alignItems: _.alignItems,
  justifyContent: _.justify,
  margin: _.margin,
  [`@media screen and (min-width: ${breakpoint.md}px)`]: md && {
    gridTemplateColumns: md.gridTemplateColumns,
    gridTemplateRows: md.gridTemplateRows,
    gridGap: md.gap,
    alignItems: md.alignItems,
    rowGap: md.rowGap,
    columnGap: md.columnGap,
    justifyContent: md.justify,
    margin: md.margin,
  },
}));
