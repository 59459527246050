import styled from 'styled-components';

import { breakpoint } from '@constants/theme';
import { Style as ShopStyle } from '@features/shop/style';

export const Style = {
  Wrap: styled(ShopStyle.Wrap)``,
  Container: styled(ShopStyle.Container)``,
  CartCard: styled.div`
    font-style: normal;
    display: block;
    padding: 0px 1rem;

    .card-body {
      display: block;
      padding: 0.5rem 0rem;
      object-fit: cover;
      object-position: center;
      box-sizing: border-box;
    }
  `,
  CartHeader: styled.div`
    display: block;
    font-family: 'Hiragino Kaku Gothic ProN';
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    color: #f39800;
    padding: 0.5rem 0rem;
    margin-bottom: 0.5rem;

    .card-icon {
      object-fit: cover;
      object-position: center;
      width: 32px;
      height: 32px;
      display: inline-block;
      vertical-align: bottom;
      box-sizing: border-box;
    }
  `,
  CartItems: styled.div`
    .cart-item {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #ebebeb;
      margin-top: 1.5rem;
      padding-bottom: 1rem;
      box-sizing: border-box;

      .cart-img img {
        width: 160px;
        height: 160px;
        display: block;
        border-radius: 15px;
        object-fit: cover;

        @media (max-width: ${breakpoint.md}px) {
          width: 80px;
          height: 80px;
        }
      }

      .cart-description {
        padding: 0rem 1.5rem 2rem 1.5rem;
        position: relative;
        width: 100%;
        box-sizing: border-box;

        .cart-price {
          width: 100%;
          position: absolute;
          display: flex;
          justify-content: end;
          bottom: 0px;
          left: 0px;
          box-sizing: border-box;
          text-align: right;
          font-family: 'Inter';
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #000000;
        }
      }
    }
  `,
  CartSummary: styled.div`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    box-sizing: border-box;
    padding: 1.5rem 0rem;
    display: flex;
    width: 100%;
  `,
  CartProductContainer: styled.div`
    display: block;
    box-sizing: border-box;
    margin-top: 1.25rem;
    padding_bottom: 1.25rem;
    border-bottom: 1px solid #ebebeb;

    .title {
      padding: 0.25rem;
      font-family: 'Inter';
      font-weight: 400;
      font-size: 21px;
      line-height: 25px;
      color: #000000;
      box-sizing: border-box;

      @media (max-width: ${breakpoint.md}px) {
        font-size: 18px;
      }
    }

    & ~ & {
      border-bottom: 0px solid #ebebeb;
    }
  `,
  Products: styled(ShopStyle.Products)``,
  EGiftHint: styled.div`
    background: #ffd898;
    border-radius: 5px;
    display: block;
    box-sizing: border-box;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding: 0.5rem 1.25rem;

    img {
      display: inline-block;
      width: 50px;
      height: 50px;
      object-fit: cover;
      background: transparent;
      margin-right: 0.5rem;
      vertical-align: middle;
    }
  `,
};
