import styled from 'styled-components';

import Container from '@components/layout/common/container';
import { RowStyle } from '@components/layout/common/row';
import Wrap from '@components/layout/common/wrap';
import { breakpoint } from '@constants/theme';
import { Style as CartStyle } from '@features/cart/style';

export const Style = {
  Wrap: styled(Wrap)`
    padding: 3rem 1rem;
  `,
  Container: styled(Container)`
    padding: 1rem 1rem;
    background: #fdf6e8;
    border: 1px solid #ffebc3;
    border-radius: 10px;
    min-height: 300px;
    text-align: center;
    margin-bottom: 2rem;

    &.bg-white {
      background: white;
      border: 0px;
    }

    & > img {
      display: block;
      object-fit: contain;
      object-position: center;
      margin: 2rem auto;
      width: 60%;
      max-width: 200px;
      height: 100%;
      max-height: 150px;
    }

    form {
      display: block;
      width: 100%;
      max-width: 360px;
      margin: auto;

      .form-group {
        display: block;
        margin-bottom: 1rem;
        width: 100%;
      }
    }
  `,
  GiftTemplateContainer: styled(RowStyle)`
    margin-bottom: 1rem;
    margin-top: 1rem;

    .template {
      min-width: 200px;
      margin-bottom: 1rem;
      margin-top: 1rem;
      cursor: pointer;

      @media (max-width: ${breakpoint.md}px) {
        min-width: 150px;
      }

      img {
        object-fit: cover;
        display: block;
        width: 100%;
        max-width: 180px;
        margin: auto;
        box-shadow: 3px 3px 5px lightgrey;
        border-radius: 25px;

        @media (max-width: ${breakpoint.md}px) {
          max-width: 100%;
          margin: auto;
        }
      }

      &.active img {
        border: 1px solid var(--color-bg-orange);
      }
    }
  `,
  GiftCard: styled.div`
    box-shadow: 3px 3px 5px lightgrey;
    border-radius: 20px;
    margin: 2rem auto;
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    display: block;

    @media (max-width: ${breakpoint.md}px) {
      max-width: 335px;
    }

    .template-header {
      width: 100%;
      height: 215px;
      display: block;
      margin: 0px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      overflow: hidden;

      @media (max-width: ${breakpoint.md}px) {
        height: 160px;
      }

      & > img {
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
        @media (max-width: ${breakpoint.md}px) {
          object-position: center;
        }
      }
    }

    .template-content {
      box-sizing: border-box;
      padding: 1.5rem 2rem;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: black;
      background: white;
      display: flex;
      flex-direction: column;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      min-height: 200px;

      .template1 {
        color: #c94014;
      }

      .template2 {
        color: #a55e12;
      }

      .template3 {
        color: #1886b7;
      }

      .template4 {
        color: #000000;
      }

      .template5 {
        color: #ff866d;
      }

      .template6 {
        color: #b5130b;
      }

      .template7 {
        color: #735628;
      }

      .template8 {
        color: #0a9aa8;
      }
    }
  `,
  GiftHeader: styled(CartStyle.CartHeader)`
    display: inline-flex;
    justify-content: start;
    align-items: center;
    border-bottom: 1px solid lightgray;
    width: 100%;

    .sub-title {
      display: inline-block;
      box-sizing: border-box;
      padding: 0px 10px;
      background: #ffd898;
      border-radius: 5px;
      margin: 0px 10px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #000000;

      img {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 5px;
        vertical-align: middle;
      }
    }
  `,
  GiftAddressContainer: styled(RowStyle)`
    padding: 1rem 1rem;

    @media screen and (max-width: ${breakpoint.md}px) {
      flex-direction: column;
    }

    .e-gift-address-info {
      flex-grow: 1;
      width: calc(100% - 290px);
      @media screen and (max-width: ${breakpoint.md}px) {
        width: 100%;
      }

      > * {
        max-width: 490px;
        @media screen and (max-width: ${breakpoint.md}px) {
          margin: 0px auto;
        }
      }
    }

    .e-gift-price-summary {
      flex-grow: 1;
      max-width: 290px;
      margin: 0px auto;
      @media screen and (max-width: ${breakpoint.md}px) {
        order: 1;
      }
    }
  `,
};
