import React, { FC, PropsWithChildren } from 'react';

import ButtonStyle from './style';

interface Props extends PropsWithChildren {
  className?: string;
  borderRadius?: string;
  width?: string;
  padding?: string;
  marginX?: string;
  plain?: boolean;
  inline?: boolean;
  link?: boolean;
  disabled?: boolean;
  onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Button: FC<Props> = ({
  className = '',
  inline,
  children,
  borderRadius = '10px',
  width = '100%',
  padding = '.5rem 1.25rem',
  plain,
  link,
  disabled,
  marginX = '',
  onClick,
}) => (
  <ButtonStyle
    type="button"
    style={{
      borderRadius,
      width: width === 'auto' ? 'auto' : '100%',
      maxWidth: width === 'auto' ? 'none' : width,
      marginLeft: width === 'auto' ? marginX || 'initial' : marginX || 'auto',
      marginRight: width === 'auto' ? marginX || 'initial' : marginX || 'auto',
      padding,
    }}
    inline={inline}
    disabled={disabled}
    className={className + (plain ? ' plain ' : ' ') + (link ? ' link ' : '')}
    onClick={onClick}
  >
    {children}
  </ButtonStyle>
);

export default React.memo(Button);
