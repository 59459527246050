import styled from 'styled-components';

export default styled.button<{ inline?: boolean }>`
  background: var(--color-bg-orange, #f39800);
  display: ${(props) => (props.inline ? 'inline-block' : 'block')} !important;
  box-sizing: border-box;
  font-family: 'Hiragino Kaku Gothic ProN', 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center !important;
  color: white !important;
  margin: 5px 10px;
  transition: opacity 0.3s, color 0.3s, border 0.3s, background 0.3s;
  position: relative;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    box-shadow: 3px 3px 3px lightgray;
  }

  &.plain {
    background: white !important;
    color: var(--color-bg-orange, #f39800) !important;
    border: 1px solid var(--color-bg-orange, #f39800);

    &:hover,
    &:active {
      opacity: 1;
      box-shadow: none;
      background: #fffcf5 !important;
    }
  }

  &.link {
    background: transparent !important;
    text-decoration-line: underline;
    color: #707070 !important;

    &:hover {
      opacity: 1;
      color: lightgray !important;
    }
  }

  &.secondary {
    background: #f4f3f2 !important;
    color: black !important;
    border: 1px solid lightgray;

    &.plain {
      background: white !important;
      border: 1px solid #f4f3f2;

      &:hover,
      &:active {
        opacity: 1;
        box-shadow: none;
        background: #d2d2d2;
      }
    }
  }

  & > * {
    vertical-align: bottom;
  }

  &[disabled] {
    pointer-events: none !important;
    opacity: 0.6 !important;
  }
`;
